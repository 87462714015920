import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { BookingReferenceGen } from "@/services/booking/v1/data-contracts";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { SignReferenceGen } from "@/services/sign/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@IsFilterable
class ReferenceEntity implements MrfiktivReferenceGen, SignReferenceGen, ThgReferenceGen, BookingReferenceGen {
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.reference.refID"
  })
  refId: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.reference.refType",
    config: {
      items: Object.values(BackendResourceEnum).map(v => {
        return {
          text: `enums.BackendResourceEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  refType: BackendResourceEnum;

  constructor(sharedContent?: Partial<IReference> | SignReferenceGen) {
    this.refId = sharedContent?.refId || "";
    this.refType = sharedContent?.refType as BackendResourceEnum;
  }
}
type IReference = ReferenceEntity;
const Reference = Filter.createForClass(ReferenceEntity);

export { IReference, Reference };
