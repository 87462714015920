import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "feature",
  store
})
export class FeatureStore extends VuexModule {
  /**
   * Get the current value of the interactive camera setting.
   */
  get isInteractiveCameraEnabled(): boolean {
    return true;
  }

  /**
   * Get the current value of the Pagination setting.
   */
  get isPaginationEnabled(): boolean {
    return true;
  }

  /**
   * Get the current value of the progressStatusEnabled setting.
   */
  get isUpdateProgressStatusEnabled(): boolean {
    return true;
  }

  /**
   * True if settings control is enabled
   */
  get isPartnerManagerEnabled(): boolean {
    return true;
  }

  /**
   * True if partner analytics is enabled.
   */
  get isPartnerAnalyticsEnabled(): boolean {
    return true;
  }

  /**
   * True if multipartner analytics is enabled.
   */
  get isMultipartnerAnalyticsEnabled(): boolean {
    return true;
  }

  /**
   * Get the current value of the isPdfUploadEnabled
   */
  get isPdfUploadEnabled(): boolean {
    return true;
  }

  /**
   * Get the current value of the template setting.
   */
  get isTemplateEnabled(): boolean {
    return true;
  }

  _fleet: IFleetFeature = {
    isAppointmentEnabled: true
  };

  /**
   * Get the current value of the fleet.
   */
  get isFleetFeatureEnabled(): IFleetFeature {
    return this._fleet;
  }

  @Mutation
  private _mutateIsFleetFeatureEnabled(fleetFeatureEnabled: IFleetFeature) {
    this._fleet = fleetFeatureEnabled;
  }

  @Action
  public setIsAppointmentEnabled(isAppointmentEnabled: boolean) {
    const fleet = this.isFleetFeatureEnabled;
    fleet.isAppointmentEnabled = isAppointmentEnabled;
    this.context.commit("_mutateIsFleetFeatureEnabled", fleet);
  }

  /**
   * Get the current value of the signature.
   */
  get isDigitalSignatureEnabled(): IDigitalSignatureFeature {
    const signatureFeature: IDigitalSignatureFeature = {
      isSignEnabled: true
    };
    return signatureFeature;
  }

  /**
   * Get the current value of the report.
   */
  get isReportEnabled(): IReportFeature {
    const report: IReportFeature = {
      isSharingEnabled: true,
      isTimelineEnabled: true
    };

    return report;
  }

  /**
   * Get the current default value of the marketplace.
   */
  get isMarketplaceEnabled(): IMarketplaceFeature {
    const isMarketplaceEnabled: IMarketplaceFeature = {
      isMarketplaceEnabled: true,
      isInsuranceCheckEnabled: true
    };

    return isMarketplaceEnabled;
  }

  /**
   * Setting for reportAdvertismentEnabled.
   */
  private _isReportAdvertisementEnabled =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isReportAdvertisementEnabled || false;

  /**
   * Get the current value of the reportAdvertismentEnabled setting.
   */
  get isReportAdvertisementEnabled(): boolean {
    return this._isReportAdvertisementEnabled;
  }

  /**
   * Dispatches an action to set the report advetisement enabled property.
   *
   * @param value
   */
  @Action
  setReportAdvertisementEnabled(value: boolean) {
    this.context.commit("updateReportAdvertisementEnabled", value);
  }

  /**
   * Mutates the report advetisement enabled property.
   *
   * @param value
   */
  @Mutation
  updateReportAdvertisementEnabled(value: boolean) {
    this._isReportAdvertisementEnabled = value;
  }

  /**
   * Get the current value of the isInviteFriendsEnabled setting.
   */
  get isInviteFriendsEnabled(): boolean {
    return true;
  }

  /**
   * Setting for showing the renewable energy flag in the charging station form
   * and upload of proofs
   */
  private _isRenewableChargingStationEnabled =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isRenewableChargingStationEnabled || true;

  /**
   * Get the setting for showing renewable energy flag in the charging station form
   */
  get isRenewableChargingStationEnabled(): boolean {
    return this._isRenewableChargingStationEnabled;
  }

  /**
   * Dispatches an action to set setting for showing renewable energy flag in the charging station form
   *
   * @param value
   */
  @Action
  public setIsRenewableChargingStationEnabled(value: boolean) {
    this.context.commit("_mutateIsRenewableChargingStationEnabled", value);
  }

  /**
   * Mutates the setting for showing renewable energy flag in the charging station form
   *
   * @param value
   */
  @Mutation
  private _mutateIsRenewableChargingStationEnabled(value: boolean) {
    this._isRenewableChargingStationEnabled = value;
  }

  /**
   * Setting for partner colors
   */
  private _isVuetifyInPartnerColors =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isVuetifyInPartnerColors || false;
  /**
   * Setting for partner colors
   */
  private _isNavigationInPartnerColors =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isNavigationInPartnerColors || false;
  /**
   * Setting for partner colors
   */
  get isVuetifyInPartnerColors() {
    return this._isVuetifyInPartnerColors;
  }
  /**
   * Setting for partner colors
   */
  get isNavigationInPartnerColors() {
    return this._isNavigationInPartnerColors;
  }

  @Mutation
  private _mutateIsVuetifyInPartnerColors(isVuetifyInPartnerColors: boolean) {
    this._isVuetifyInPartnerColors = isVuetifyInPartnerColors;
  }
  @Mutation
  private _mutateIsNavigationInPartnerColors(isNavigationInPartnerColors: boolean) {
    this._isNavigationInPartnerColors = isNavigationInPartnerColors;
  }

  @Action
  public setIsVuetifyInPartnerColors(isVuetifyInPartnerColors: boolean) {
    this.context.commit("_mutateIsVuetifyInPartnerColors", isVuetifyInPartnerColors);
  }
  @Action
  public setIsNavigationInPartnerColors(isNavigationInPartnerColors: boolean) {
    this.context.commit("_mutateIsNavigationInPartnerColors", isNavigationInPartnerColors);
  }

  /**
   * Get the current value of the online booking feature flag.
   */
  get onlineBooking(): boolean {
    return true;
  }

  /**
   * Setting for upload attachments for online booking
   */
  private _attachmentsOnlineBooking: boolean =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._attachmentsOnlineBooking || false;

  get attachmentsOnlineBooking(): boolean {
    return this._attachmentsOnlineBooking;
  }

  @Mutation
  private _mutateSetAttachmentsOnlineBooking(value: boolean) {
    this._attachmentsOnlineBooking = value;
  }

  @Action
  public setAttachmentsOnlineBooking(value: boolean) {
    this.context.commit("_mutateSetAttachmentsOnlineBooking", value);
  }

  /**
   * Setting on billing page to check if the billing distribution is enabled
   */
  private _isBillingDistributionEnabled: boolean =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isBillingDistributionEnabled || false;
  get isBillingDistributionEnabled(): boolean {
    return this._isBillingDistributionEnabled;
  }
  @Mutation
  private _mutateIsBillingDistributionEnabled(value: boolean) {
    this._isBillingDistributionEnabled = value;
  }
  @Action
  public setIsBillingDistributionEnabled(value: boolean) {
    this.context.commit("_mutateIsBillingDistributionEnabled", value);
  }
  /**
   * Feature Flag for billing overview for partner
   */
  private _isPartnerBillingOverviewEnabled = true;
  get isPartnerBillingOverviewEnabled(): boolean {
    return this._isPartnerBillingOverviewEnabled;
  }
  @Mutation
  private _mutateIsPartnerBillingOverviewEnabled(value: boolean) {
    this._isPartnerBillingOverviewEnabled = value;
  }
  @Action
  public setIsPartnerBillingOverviewEnabled(value: boolean) {
    this.context.commit("_mutateIsPartnerBillingOverviewEnabled", value);
  }
  /**
   * Feature Flag for uploading documents in the report
   */
  private _isDocumentUploadEnabled = true;
  get isDocumentUploadEnabled(): boolean {
    return this._isDocumentUploadEnabled;
  }
  @Mutation
  private _mutateIsDocumentUploadEnabled(value: boolean) {
    this._isDocumentUploadEnabled = value;
  }
  @Action
  public setIsDocumentUploadEnabled(value: boolean) {
    this.context.commit("_mutateIsDocumentUploadEnabled", value);
  }

  /**
   * Setting for showing option to isInboxEnabled
   */
  private _isInboxEnabled = JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isInboxEnabled || false;

  /**
   * Get the current value of the isInboxEnabled
   */
  get isInboxEnabled(): boolean {
    return true;
  }

  /**
   * Dispatches an action to enable the option to export to ksr
   *
   * @param value The value to set the option to.
   */
  @Action
  public setIsInboxEnabled(value: boolean) {
    this.context.commit("_mutateInboxEnabled", value);
  }

  /**
   * Mutates the option to export to ksr
   *
   * @param value The value to set the option to export to ksr.
   */
  @Mutation
  private _mutateInboxEnabled(value: boolean) {
    this._isInboxEnabled = value;
  }

  /**
   * Setting for showing option to enable new signature form
   */
  private _isSignatureFormActive =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isSignatureFormActive || false;

  /**
   * Get the current value of option to enable new signature form
   */
  get isSignatureFormActive(): boolean {
    return true;
  }

  /**
   * Dispatches an action to enable new signature form
   *
   * @param value The value to set the option to.
   */
  @Action
  public setIsSignatureFormActive(value: boolean) {
    this.context.commit("_mutateIsSignatureFormActive", value);
  }

  /**
   * Mutates the option to use ticket
   *
   * @param valu
   */
  @Mutation
  private _mutateIsSignatureFormActive(value: boolean) {
    this._isSignatureFormActive = value;
  }

  /**
   * Setting for showing option to enable ticket
   */
  private _isTicketEnabled =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isTicketEnabled || false;

  /**
   * Get the current value of option to enable  ticket
   */
  get isTicketEnabled(): boolean {
    return this._isTicketEnabled;
  }

  /**
   * Dispatches an action to enable  ticket
   *
   * @param value The value to set the option to.
   */
  @Action
  public setIsTicketEnabled(value: boolean) {
    this.context.commit("_mutateIsTicketEnabled", value);
  }

  /**
   * Mutates the option to use ticket
   *
   * @param value
   */
  @Mutation
  private _mutateIsTicketEnabled(value: boolean) {
    this._isTicketEnabled = value;
  }

  /**
   * Setting for showing option to enable ticket
   */
  private _isEvseEnabled = JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isEvseEnabled || false;

  /**
   * Get the current value of option to enable  ticket
   */
  get isEvseEnabled(): boolean {
    return this._isEvseEnabled;
  }

  /**
   * Dispatches an action to enable  ticket
   *
   * @param value The value to set the option to.
   */
  @Action
  public setIsEvseEnabled(value: boolean) {
    this.context.commit("_mutateIsEvseEnabled", value);
  }

  /**
   * Mutates the option to use evse
   *
   * @param value
   */
  @Mutation
  private _mutateIsEvseEnabled(value: boolean) {
    this._isEvseEnabled = value;
  }

  /**
   * Setting for showing option to enable map
   */
  private _isMapShown = JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isMapShown || false;

  /**
   * Get the current value of option to enable map
   */
  get isMapShown(): boolean {
    return this._isMapShown;
  }

  /**
   * Dispatches an action to enable map
   *
   * @param value The value to set the option to.
   */
  @Action
  public setIsMapShown(value: boolean) {
    this.context.commit("_mutateIsMapShown", value);
  }

  /**
   * Mutates the option to use evse
   *
   * @param value
   */
  @Mutation
  private _mutateIsMapShown(value: boolean) {
    this._isMapShown = value;
  }

  /**
   * Setting for showing option to hash email in gtm
   */
  private _isGtmHashEmail = JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isGtmHashEmail || false;

  /**
   * Enables email hashing in GTM store
   */
  get isGtmHashEmail(): boolean {
    return this._isGtmHashEmail;
  }

  @Action
  public setIsGtmHashEmail(value: boolean) {
    this.context.commit("_mutateIsGtmHashEmail", value);
  }

  @Mutation
  private _mutateIsGtmHashEmail(value: boolean) {
    this._isGtmHashEmail = value;
  }

  private _isCsvExport = JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isCsvExport || false;

  get isCsvExport() {
    return this._isCsvExport || true;
  }

  @Action
  public setIsCsvExport(value: boolean) {
    this.context.commit("_mutateIsCsvExport", value);
  }

  @Mutation
  private _mutateIsCsvExport(value: boolean) {
    this._isCsvExport = value;
  }

  private _isQrCodeMultipleDownload =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isQrCodeMultipleDownload || false;

  get isQrCodeMultipleDownload() {
    return this._isQrCodeMultipleDownload;
  }

  @Action
  public setIsQrCodeMultipleDownload(value: boolean) {
    this.context.commit("_mutateIsQrCodeMultipleDownload", value);
  }

  @Mutation
  private _mutateIsQrCodeMultipleDownload(value: boolean) {
    this._isQrCodeMultipleDownload = value;
  }

  private _isExtendedAcknowledgement =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isExtendedAcknowledgement || false;

  get isExtendedAcknowledgement() {
    return this._isExtendedAcknowledgement;
  }

  @Action
  public setIsExtendedAcknowledgement(value: boolean) {
    this.context.commit("_mutateIsExtendedAcknowledgement", value);
  }

  @Mutation
  private _mutateIsExtendedAcknowledgement(value: boolean) {
    this._isExtendedAcknowledgement = value;
  }

  private _isFileSelectionOnShortPressInImageUpload =
    JSON.parse(window.localStorage.getItem("feature") || "{}").state?._isFileSelectionOnShortPressInImageUpload ||
    false;

  get isFileSelectionOnShortPressInImageUpload() {
    return this._isFileSelectionOnShortPressInImageUpload;
  }

  @Action
  public setIsFileSelectionOnShortPressInImageUpload(value: boolean) {
    this.context.commit("_mutateIsFileSelectionOnShortPressInImageUpload", value);
  }

  @Mutation
  private _mutateIsFileSelectionOnShortPressInImageUpload(value: boolean) {
    this._isFileSelectionOnShortPressInImageUpload = value;
  }
}

export const FeatureModule = getModule(FeatureStore);

export interface IFleetFeature {
  isAppointmentEnabled: boolean;
}

export interface IDigitalSignatureFeature {
  isSignEnabled: boolean;
}

export interface IReportFeature {
  isTimelineEnabled: boolean;
  isSharingEnabled: boolean;
}

export interface IMarketplaceFeature {
  isMarketplaceEnabled: boolean;
  isInsuranceCheckEnabled: boolean;
}
