



























































































import CameraBottomSheet from "@/components/camera/CameraBottomSheet.vue";
import CameraDialog from "@/components/camera/CameraDialog.vue";
import FileExample from "@/components/files/FileExample.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import PdfUploadDialog from "@/components/files/PdfUploadDialog.vue";
import PdfViewer from "@/components/files/PdfViewer.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { isMobile } from "@/lib/utility/isMobile";
import Debug from "./Debug.vue";

@Component({
  components: {
    PdfViewer,
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload,
    CameraDialog,
    CameraBottomSheet,
    PdfUploadDialog,
    Debug
  }
})
/**
 * Uploads images as overview images
 */
export default class FileUploadPreview extends mixins(ReportImageSubmission) {
  readonly LONG_PRESS_DURATION_IN_MS = 750;

  @Ref("sheet") sheet!: CameraBottomSheet;

  @Ref("uploader") uploader!: HTMLInputElement;

  @Ref("uploaderWithoutFileSelectionDialog") uploaderWithoutFileSelectionDialog!: HTMLInputElement;

  @Prop()
  example!: AssetEnum;

  @Prop()
  displayFiles!: IImageUploaded[];

  @Prop({ default: null })
  public maxFiles!: number | null;

  @Prop({ default: 1 })
  public minFiles!: number;

  @Prop()
  onUpload!: (file: File) => Promise<boolean>;

  @Prop()
  onDelete!: (file: File) => Promise<boolean>;

  @Prop()
  asset!: AssetEnum;

  @Prop()
  instruction!: IReportInstructionData;

  /**
   * Text to be displayed on the button card.
   */
  @Prop({ default: "defaultFoto" })
  text: any;

  /**
   * An alternative text to be displayed on @isUploade
   */
  @Prop({ default: "defaultFotoAgain" })
  textAlt: any;

  mouseDownTimer: NodeJS.Timeout | null = null;

  debugLog = "";

  get inputTypes() {
    return ConfigModule.pdfInputTypesString;
  }

  get imageInputTypes() {
    return ConfigModule.imageInputTypes.join(",");
  }

  get files(): IImageUploaded[] {
    return this.displayFiles;
  }

  get isTouch() {
    return isMobile();
  }

  onFileDrag(file: File) {
    this.handleInput(file);
  }

  async handleUpload(file: File): Promise<boolean> {
    await this.onUpload(file);
    return true;
  }

  async handleDelete(file: File): Promise<boolean> {
    await this.onDelete(file);

    return true;
  }

  mounted() {
    if (this.isCaptureEnvironment) {
      if (this.isTouch) {
        (this.$refs.uploadButton as Vue).$el.addEventListener("touchstart", this.onMouseDown);
        (this.$refs.uploadButton as Vue).$el.addEventListener("touchend", this.onMouseUp);
      } else {
        (this.$refs.uploadButton as Vue).$el.addEventListener("mousedown", this.onMouseDown);
        (this.$refs.uploadButton as Vue).$el.addEventListener("mouseup", this.onMouseUp);
      }
    }
  }

  beforeDestroy() {
    if (this.isCaptureEnvironment) {
      if (this.isTouch) {
        (this.$refs.uploadButton as Vue).$el.removeEventListener("touchstart", this.onMouseDown);
        (this.$refs.uploadButton as Vue).$el.removeEventListener("touchend", this.onMouseUp);
      } else {
        (this.$refs.uploadButton as Vue).$el.removeEventListener("mousedown", this.onMouseDown);
        (this.$refs.uploadButton as Vue).$el.removeEventListener("mouseup", this.onMouseUp);
      }
    }
  }

  onMouseDown() {
    this.debugLog += "onMouseDown<br/>";
    this.mouseDownTimer = setTimeout(() => {
      this.debugLog += "Timeout Executed<br/>";
      this.mouseDownTimer = null;
      this.onRightClick();
    }, this.LONG_PRESS_DURATION_IN_MS);
  }

  onMouseUp() {
    this.debugLog += "onMouseUp<br/>";

    if (FeatureModule.isFileSelectionOnShortPressInImageUpload) {
      this.debugLog += "Feature Flag<br/>";
      this.onRightClick();
    } else if (this.mouseDownTimer) {
      this.onClick();
    }

    if (this.mouseDownTimer) {
      this.debugLog += "Timeout Interrupted<br/>";
      clearTimeout(this.mouseDownTimer);
      this.mouseDownTimer = null;
    }
  }

  onRightClick() {
    this.debugLog += "file browser dialog<br/>";
    this.rightClick();
  }

  onClick() {
    this.debugLog += "jump to camera<br/>";
    this.click();
  }
}
