
import CameraBottomSheet from "@/components/camera/CameraBottomSheet.vue";
import { BadRequestException, InternalServerErrorException } from "@/lib/exceptions/http";
import { NetworkError } from "@/lib/exceptions/http/NetworkError";
import { isImage } from "@/lib/files/ImageHelper";
import { isPdf } from "@/lib/files/PdfHelper";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { handleError } from "@/lib/utility/handleError";
import { isMobile } from "@/lib/utility/isMobile";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Prop, Ref } from "vue-property-decorator";
import ReportStepMixin from "./ReportStepMixin.vue";

@Component({})
export default class ReportImageSubmission extends ReportStepMixin implements IViewImageUploadable {
  /**
   * This setting will open the back camera on a mobile device
   */
  @Prop({ default: false })
  isCaptureEnvironment?: boolean;

  @Ref("sheet") sheet!: CameraBottomSheet;

  @Ref("uploader") uploader!: HTMLInputElement;

  @Ref("uploaderWithoutFileSelectionDialog") uploaderWithoutFileSelectionDialog!: HTMLInputElement;

  get isPdfUploadEnabled() {
    return FeatureModule.isPdfUploadEnabled;
  }

  /**
   * is Dialog to take pictures active
   */
  isCameraDialogActive = false;

  /**
   * Dialog for interactive camera upload.
   */
  public dialog = false;

  /**
   * Dialog for PDF Upload
   */
  public pdfDialog = false;

  /**
   * The pdf file to upload
   */
  public pdf: File | null = null;

  /**
   * Min Files indicate the minium required files to be uploaded.
   * Validated via the `isDone()` method.
   * default = 1 (require one image at least by default).
   */
  public minFiles = 1;

  /**
   * Max Files indicate wether a given maximum of files for the component is set.
   * 0 = no limit
   */
  public maxFiles!: number | null;

  /**
   * Default text of the `ImageUploadButton.vue` depending on single or multi file upload.
   */
  public defaultAltTextByMaxFiles = this.maxFiles === 1 ? "Foto erneut aufnehmen?" : "Weiteres Foto aufnehmen?";

  /**
   * Default value to set the header width.
   */
  public md = 6;

  /**
   * Indicates a current upload is in progress
   */
  public isLoading = false;

  /**
   * Indicates wether a file is uploaded.
   */
  public isUploaded = false;

  /**
   * Indicates wether the given ReportStep is complete.
   * @returns {boolean} Image Upload is done
   */
  get isDone(): boolean {
    if (!this.files) return false;

    const isDone: boolean = this.files.length >= this.minFiles;
    return isDone;
  }

  get isUserAgentMobile() {
    return isMobile();
  }

  async onImageExport(image: File) {
    try {
      this.isLoading = true;
      this.closePdfDialog();
      await this.handleMaxFiles();
      await this.handleUpload(image);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handles the click event of the image upload button. Does not even try to jump straight into camera, but instead opens menu asking for files
   */
  public rightClick() {
    if (
      !isMobile() ||
      !ConfigModule.isCameraAvailable ||
      !FeatureModule.isInteractiveCameraEnabled ||
      this.isCaptureEnvironment
    ) {
      if (this.uploader) {
        this.uploader.click();
      } else {
        this.$toast(this.$t("mixins.ReportImageSubmissionMixin.noUploader"));
      }
    } else {
      this.sheet.open();
    }
  }

  /**
   * Handles the click event of the image upload button
   */
  public click() {
    if (
      !isMobile() ||
      !ConfigModule.isCameraAvailable ||
      !FeatureModule.isInteractiveCameraEnabled ||
      this.isCaptureEnvironment
    ) {
      if (this.isUserAgentMobile && this.isCaptureEnvironment && this.uploaderWithoutFileSelectionDialog) {
        // on a *mobile device* we want to jump straight into the camera if isCaptureEnvironment is active.
        this.uploaderWithoutFileSelectionDialog.click();
      } else if (this.uploader) {
        this.uploader.click();
      } else {
        this.$toast(this.$t("mixins.ReportImageSubmissionMixin.noUploader"));
      }
    } else {
      this.sheet.open();
    }
  }

  /**
   * Input method for handling file event.
   */
  public async onFileInputEvent(event: any) {
    const file = event.target.files[0];
    await this.handleInput(file);

    // https://stackoverflow.com/questions/48309126/vue-file-upload-uploading-the-same-file-consecutively
    this.uploader.value = "";
  }

  /**
   * Private function to handle the on file input event.
   */
  public async handleInput(file: File) {
    this.pdf = null;
    if (isImage(file)) {
      this.uploaded(file);
      // this.emitUploadedFile(file);
    } else if (isPdf(file) && this.isPdfUploadEnabled) {
      this.pdf = file;
      this.startPdfDialog();
    } else {
      this.displayNotACorrectFileType();
    }
  }

  /**
   * starts dialog to edit pdfs
   */
  startPdfDialog() {
    this.pdfDialog = true;
  }

  /**
   * closes Pdf Dialog
   */
  closePdfDialog() {
    this.pdfDialog = false;
  }

  /**
   * Private function to handle the upload event for a pdf
   */
  handlePdfUploaded(file: File) {
    this.uploaded(file);
    this.closePdfDialog();
  }

  /**
   * Helper method to display error message.
   */
  public displayNotACorrectFileType() {
    this.$log.error("Not an image/pdf found");
    this.$toast.error(
      this.isPdfUploadEnabled
        ? this.$t("mixins.ReportImageSubmissionMixin.wrongFormat")
        : this.$t("mixins.ReportImageSubmissionMixin.wrongFormatOld")
    );
  }

  /**
   * Emit an event to the partent vue component.
   */
  public emitUploadedFile(file: File) {
    this.$emit("uploaded", file);
  }

  /**
   * Callback function for the change event on image upload button.
   * Via `ImageUploadButton.vue`
   *
   * @param event any - The event that contains the files to uploaded.
   */
  private async onFileInput(event: any) {
    const file = event.target.files[0];
    await this.handleFileAsImage(file);
  }

  /**
   * Internal function to abstract the event and handling of file type.
   * Requires an image to be passed, otherwise displays error.
   *
   * @params {File} file - file to be processed
   */
  protected async handleFileAsImage(file: File) {
    await this.handleInput(file);
  }

  /**
   * Handles the upload of a given file type.
   * Needs to be overwritten by each view to implement a given file type upload.
   * @param file - file to upload
   */
  public async handleUpload(file: File): Promise<boolean> {
    throw Error(`handleUpload not implemented ${file.name}`);
  }

  /**
   * Handles the deletion of uploaded file.
   * Needs to be overwritten by each view to implement a given file type deletion.
   * @param file - file to be removed
   */
  public async handleDelete(file: File): Promise<boolean> {
    throw Error(`handleDelete not implemented ${file.name}`);
  }

  /**
   * Returns the given files of the store by file type.
   * Needs to be overwritten by each view to implement a given file type.
   */
  get files(): IImageUploaded[] {
    throw Error("files not implemented");
  }

  /**
   * Computed property for the color.
   */
  get color() {
    return getDefaultPartnerColor();
  }

  /**
   * if the upper limit on the amount of files is exceeded the oldest files are deleted until the limit is reached
   */
  async handleMaxFiles() {
    if (this.maxFiles && this.files.length >= this.maxFiles) {
      await this.handleDelete(this.files[this.files.length - 1].file);
    }
  }

  /**
   * Handles the event uploaded.
   * Sets the private loading and uploaded flags.
   * Calls the parent `handleUpload()` and `handleDeleted()` methods.
   * If `maxFiles` is set, the `handleDeleted()` method is called,
   * using the last element of the files so that the oldest element is overwritten
   * and the component only stores the max files required.
   *
   * @param event - event that contains the file to be uploaded
   */
  private async uploaded(e: any) {
    if (!e) return;

    this.$log.info(e);

    this.isUploaded = false;
    this.isLoading = true;

    const file = e;

    await this.handleMaxFiles();

    try {
      if (file) {
        const success = await this.handleUpload(file);
        this.isUploaded = success;
        if (!success) {
          this.$toast.error(this.$t("mixins.ReportImageSubmissionMixin.defaultError"));
        }
      }
    } catch (error) {
      this.$toast.error(this.$t(this.imageUploadErrorToErrorMessageMapper(error)));
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handles the event deletion.
   * Sets the private loading and uploaded flags.
   * Calls the parent `handleUpload()` and `handleDeleted()` methods.
   *
   * @param event - event that contains the file to be uploaded
   */
  public async deleted(e: any) {
    if (!e) return;

    this.$log.info(e);

    this.isUploaded = false;
    this.isLoading = true;

    const file = e;

    try {
      if (file) {
        const success = await this.handleDelete(file);
        if (!success) {
          this.$toast.error(this.$t("mixins.ReportImageSubmissionMixin.defaultDeleteError"));
        }
      }
    } catch (error) {
      this.$toast.error(this.$t("mixins.ReportImageSubmissionMixin.defaultDeleteError"));
    }

    this.isLoading = false;
  }

  /**
   * Handle the image upload error and maps to a toast.
   */
  imageUploadErrorToErrorMessageMapper(error: any) {
    switch (error.constructor) {
      case NetworkError:
        return "mixins.ReportImageSubmissionMixin.networkError";
      case InternalServerErrorException:
        return "mixins.ReportImageSubmissionMixin.internalServerError";
      case BadRequestException:
        return "mixins.ReportImageSubmissionMixin.badRequest";
      default:
        return "mixins.ReportImageSubmissionMixin.defaultError";
    }
  }
}
