var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "fill-height": ""
    }
  }, [_vm.displayFiles.length < 1 ? _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "6",
      "sm": "6",
      "xs": "6"
    }
  }, [_c('file-example', {
    attrs: {
      "data-test-file-example": "",
      "example": _vm.example
    }
  })], 1) : _vm._e(), _vm._l(_vm.displayFiles, function (item, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "6",
        "md": "6",
        "sm": "6",
        "xs": "6"
      }
    }, [_c('file-upload', {
      attrs: {
        "data-test-file-uploaded": "",
        "item": item
      },
      on: {
        "deleted": _vm.handleDelete
      }
    }), _vm._t("file", null, {
      "item": item
    })], 2);
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "6",
      "sm": "6",
      "xs": "6"
    }
  }, [_c('image-upload-button', {
    ref: "uploadButton",
    attrs: {
      "text": _vm.text,
      "textAlt": _vm.textAlt,
      "isUploaded": _vm.files && _vm.files.length > 0,
      "isLoading": _vm.isLoading
    },
    on: {
      "onDrop": _vm.onFileDrag,
      "click": function click() {
        if (!_vm.isCaptureEnvironment) { _vm.click(); }
      }
    }
  }), _vm._t("upload")], 2), _c('input', {
    ref: "uploader",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-test-report-image-upload": "",
      "name": "file",
      "type": "file",
      "accept": _vm.inputTypes
    },
    on: {
      "change": _vm.onFileInputEvent
    }
  }), _c('input', {
    ref: "uploaderWithoutFileSelectionDialog",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-test-report-image-upload": "",
      "name": "file",
      "type": "file",
      "accept": _vm.imageInputTypes,
      "capture": "environment"
    },
    on: {
      "change": _vm.onFileInputEvent
    }
  }), _vm.isPdfUploadEnabled ? _c('pdf-upload-dialog', {
    attrs: {
      "isDialog": _vm.pdfDialog,
      "pdf": _vm.pdf
    },
    on: {
      "closePdfDialog": _vm.closePdfDialog,
      "onImageExport": _vm.onImageExport
    }
  }) : _vm._e(), _vm.isCameraDialogActive ? _c('camera-dialog', {
    ref: "dialog",
    attrs: {
      "instruction": _vm.instruction,
      "asset": _vm.asset,
      "handleUploadCb": _vm.handleInput,
      "handleDeleteCb": _vm.deleted,
      "images": _vm.files.map(function (file) {
        return file.file;
      })
    },
    on: {
      "close-camera-dialog": function closeCameraDialog($event) {
        _vm.isCameraDialogActive = false;
      }
    }
  }) : _vm._e(), _c('camera-bottom-sheet', {
    ref: "sheet",
    on: {
      "camera-clicked": function cameraClicked($event) {
        _vm.isCameraDialogActive = true;
      },
      "library-clicked": function libraryClicked($event) {
        return _vm.$refs.uploader.click();
      }
    }
  }), _c('debug', [_vm._v(" isCameraDialogActive " + _vm._s(_vm.isCameraDialogActive) + " "), _c('br'), _vm._v(" isTouch " + _vm._s(_vm.isTouch) + " "), _c('br'), _c('v-btn', {
    on: {
      "click": _vm.click
    }
  }, [_vm._v("click")]), _c('v-btn', {
    on: {
      "click": _vm.rightClick
    }
  }, [_vm._v("rightClick")]), _c('v-btn', {
    on: {
      "click": function click($event) {
        _vm.debugLog = '';
      }
    }
  }, [_vm._v("Clear")]), _c('v-divider'), _c('br'), _vm._v(" debugLog : "), _c('br'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.debugLog)
    }
  }), _c('v-divider')], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }