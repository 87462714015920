import { MrfiktivProjectCustomViewFieldViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";

export class ProjectCustomViewFieldBase implements MrfiktivProjectCustomViewFieldViewmodelGen {
  type: ProjectCustomViewFieldEnum;
  key: string;

  constructor(field?: Partial<MrfiktivProjectCustomViewFieldViewmodelGen>) {
    this.type = (field?.type as ProjectCustomViewFieldEnum) ?? ProjectCustomViewFieldEnum.CUSTOM_FIELD;

    // fix legacy views
    if (this.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD && !field?.key?.startsWith("values.value")) {
      this.key = "values.value?" + field?.key;
    } else if (this.type === ProjectCustomViewFieldEnum.REF && !field?.key?.startsWith("refs")) {
      this.key = "refs?" + field?.key;
    } else {
      this.key = field?.key ?? "";
    }
  }
}

type IProjectCustomViewField = ProjectCustomViewFieldBase;
const ProjectCustomViewField = ProjectCustomViewFieldBase;

export { IProjectCustomViewField, ProjectCustomViewField };
