import {
  MrfiktivDAServiceEventDetailViewModelGen,
  MrfiktivDaControllerGetServiceEventParamsGen,
  MrfiktivDaControllerGetServiceEventsParamsGen,
  MrfiktivDaExportReportDtoGen,
  MrfiktivDaWebhookControllerGetParamsGen
} from "./../v1/data-contracts";
/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AxiosInstance } from "axios";
import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import {
  MrfiktivDaControllerGetCustomersParamsGen,
  MrfiktivDaCustomerViewModelGen,
  MrfiktivDaEventTypesViewModelGen,
  MrfiktivDaSetupDtoGen,
  MrfiktivDaSetupViewModelGen,
  MrfiktivDaWorkshopServicesViewModelGen,
  MrfiktivDaWorkshopTasksViewModelGen,
  MrfiktivOperationIdViewModelGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { Da } from "./../v1/Da";
import { MrfiktivDaResourcesViewModelGen } from "./../v1/data-contracts";

/**
 * FIXME: get type from generated client
 */
export interface IGetFreeSlotDto {
  partnerId: string;
  resourceId: number;
  startDate?: string;
  endDate?: string;
  duration?: number;
}

/**
 * FIXME: get type from generated client
 */
export interface IDaSetupViewModel {
  isSetup: boolean;
  isAuthenticated: boolean;
  isMandatoryConfiguration: boolean;
  error: string;
  resource: string;
  eventType: string;
}

/**
 * FIXME: get type from generated client
 */
export interface IDaAccessTokenRequestDto {
  group: string;
  center: string;
  username: string;
  password: string;
  resource: number;
}

/**
 * FIXME: get type from generated client
 */
export type IDaExportReportDto = MrfiktivDaExportReportDtoGen;

/**
 * Action envelope for export report
 */
export interface IDaExportReportDtoEnvelope {
  partnerId: string;
  reportId: string;
  daExportReportDto: IDaExportReportDto;
}

/**
 * FIXME: get type from generated client
 */
export interface IDaFreeSlotViewModel {
  freeSlots: string[];
}

export interface IDaResourceGen {
  id?: number;
  name?: string;
  order?: number;
  staff_id?: string;
  is_visible_in_calendar?: boolean;
}

export interface IDaResourcesViewModel {
  resources: IDaResourceGen[];
}

/**
 * Service to talk to mrfiktiv daService endpoint.
 */
export class DaService {
  private client: HttpClient;
  private instance: AxiosInstance;
  private proxy: Da;

  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Da(this.client);

    this.instance = clientProvider.instance();
  }

  /**
   * Get free slot
   *
   * @param getFreeSlotDto
   * @returns
   */
  async getFreeSlot(getFreeSlotDto: IGetFreeSlotDto): Promise<IDaFreeSlotViewModel> {
    const res = await this.proxy.daControllerGetFreeSlot({ ...getFreeSlotDto });

    return res.data;
  }

  /**
   * Get free slot
   *
   * @param getFreeSlotDto
   * @returns
   */
  async findCustomers(query: MrfiktivDaControllerGetCustomersParamsGen): Promise<MrfiktivDaCustomerViewModelGen[]> {
    const res = await this.proxy.daControllerGetCustomers(query);

    return res.data;
  }

  /**
   * Get event types
   *
   * @param partnerId
   * @returns
   */
  async getEventTypes(partnerId: string): Promise<MrfiktivDaEventTypesViewModelGen> {
    const res = await this.proxy.daControllerGetEventTypes(partnerId);

    return res.data;
  }

  /**
   * Get event types
   *
   * @param partnerId
   * @returns
   */
  async getServiceEvents(
    query: MrfiktivDaControllerGetServiceEventsParamsGen
  ): Promise<MrfiktivDAServiceEventDetailViewModelGen[]> {
    const res = await this.proxy.daControllerGetServiceEvents({ ...query });

    return res.data;
  }

  /**
   * Get a event types
   *
   * @param query
   * @returns
   */
  async getServiceEvent(
    query: MrfiktivDaControllerGetServiceEventParamsGen
  ): Promise<MrfiktivDAServiceEventDetailViewModelGen> {
    const res = await this.proxy.daControllerGetServiceEvent(query);

    return res.data;
  }

  /**
   * Get workshop services
   *
   * @param partnerId
   * @returns
   */
  async getWorkshopServices(partnerId: string): Promise<MrfiktivDaWorkshopServicesViewModelGen> {
    const res = await this.proxy.daControllerGetWorkshopServices(partnerId);

    return res.data;
  }

  /**
   * Get workshop tasks
   *
   * @param partnerId
   * @returns
   */
  async getWorkshopTasks(partnerId: string): Promise<MrfiktivDaWorkshopTasksViewModelGen> {
    const res = await this.proxy.daControllerGetWorkshopTasks(partnerId);

    return res.data;
  }

  /**
   * Get resources available for the partner with partnerId
   *
   * @param partnerId the partnerid to get the resources for
   * @returns the resources available for the partner
   */
  async getResources(partnerId: string): Promise<MrfiktivDaResourcesViewModelGen> {
    const res = await this.proxy.daControllerGetResources(partnerId);

    return res.data;
  }

  /**
   * Setup the DA configuration
   *
   * @param partnerId
   * @param data
   */
  async setup(partnerId: string, data: MrfiktivDaSetupDtoGen): Promise<MrfiktivDaSetupViewModelGen> {
    const res = await this.proxy.daControllerSetup(partnerId, data);

    return res.data;
  }

  /**
   * Get da configuration
   *
   * @param partnerId the id of the partner to check the configuration of the DA
   * @returns
   */
  async getDaConfiguration(partnerId: string): Promise<MrfiktivDaSetupViewModelGen> {
    const res = await this.proxy.daControllerIsSetup(partnerId);

    return res.data;
  }

  /**
   * Export a report to the DA
   *
   * @param partnerId
   * @param reportId
   * @param daExportReportDto
   * @returns
   */
  async exportReportToDa(
    partnerId: string,
    reportId: string,
    daExportReportDto: IDaExportReportDto
  ): Promise<MrfiktivOperationIdViewModelGen> {
    const res = await this.proxy.daControllerExport(partnerId, reportId, daExportReportDto);

    return res.data;
  }

  /**
   *
   * @param data
   * @returns
   */
  async getDaWebhooks(data: MrfiktivDaWebhookControllerGetParamsGen) {
    const res = await this.proxy.daWebhookControllerGet(data);

    return res.data;
  }
}

export default new DaService(new MrfiktivHttpClientProvider());
