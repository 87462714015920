





























import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { SUPPORT_LOCALES } from "@/plugins/I18nPlugin";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "./ConfirmActionDialog.vue";
import Debug from "./Debug.vue";

interface ILanguageAutocomplete {
  text: string;
  value: string;
}

@Component({
  components: { ConfirmActionDialog, Debug },
  filters: { getFlagEmojiByLanguage }
})
export default class LanguageDialog extends DarkModeHighlightMixin {
  @Prop()
  value!: string;

  language?: LanguageCodeEnum;

  get local() {
    return this.$i18n.locale as LanguageCodeEnum;
  }

  set local(locale: LanguageCodeEnum) {
    this.$log.warn(locale);
    if (locale) {
      this.$i18n.locale = locale;
    }
    this.language = locale;
  }

  get locals() {
    return SUPPORT_LOCALES;
  }

  get items(): ILanguageAutocomplete[] {
    return this.locals.map(l => ({
      value: l,
      text: this.$t(`enums.LanguageCodeEnum.${l}`).toString()
    }));
  }

  get browserLanguage() {
    return navigator.language || (navigator as any).userLanguage;
  }

  emitNext() {
    this.$emit("next", this.language);
  }
}
