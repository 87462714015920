import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IReport, Report } from "@/models/report.entity";
import reportService from "@/services/mrfiktiv/services/reportService";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const ReportPageDataProvider = new (class extends AbstractPageDataProvider<
  IReport,
  MrfiktivReportControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivReportControllerFindAllParamsGen): Promise<IPageViewModel<IReport>> {
    const res = await reportService.getReportPaginated(query);

    const meta = res.meta;
    const data = (res.data?.map(d => new Report(d)) ?? []) as IReport[];

    return { meta, data };
  }
})();
