/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ReportType {
  CAR = "car",
  CONTAINER = "container",
  MOTORBIKE = "motorbike",
  CRANE = "crane",
  TRUCK = "truck",
  TRUCK_TRAILER = "truck_trailer",
  TRACTOR_UNIT = "tractor_unit",
  TRAILER = "trailer",
  SEMITRAILER = "semitrailer",
  BICYCLE = "bicycle",
  TRAIN = "train",
  OTHER = "other"
}
