import { MrfiktivProjectCustomViewSortByFieldViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectCustomViewFieldBase } from "./project-custom-view-field.entity";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";

class ProjectCustomViewSortByFieldBase extends ProjectCustomViewFieldBase
  implements MrfiktivProjectCustomViewSortByFieldViewmodelGen {
  /**
   * @inheritdoc
   */
  order: PageOrderEnum;

  constructor(field?: Partial<MrfiktivProjectCustomViewSortByFieldViewmodelGen>) {
    super(field);

    this.order = (field?.order as PageOrderEnum) ?? PageOrderEnum.DESCENDING;
  }
}

type IProjectCustomViewSortByField = ProjectCustomViewSortByFieldBase;
const ProjectCustomViewSortByField = ProjectCustomViewSortByFieldBase;

export { IProjectCustomViewSortByField, ProjectCustomViewSortByField };
