import { IExportReportMeta } from "@/lib/interfaces/Report/export-report-meta.interface";
import { IDaMetaData } from "@/lib/interfaces/da/IDAMetaData";
import { IKsrExportReportDto } from "@/lib/interfaces/ksr/ksr-export-report.dto";
import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import {
  default as DaService,
  IDaExportReportDtoEnvelope,
  IGetFreeSlotDto
} from "@/services/mrfiktiv/services/daService";
import { default as KsrService } from "@/services/mrfiktiv/services/ksrService";
import { default as MetaService } from "@/services/mrfiktiv/services/metaService";
import pdrService from "@/services/mrfiktiv/services/pdrService";
import {
  MrfiktivDaFreeSlotViewModelGen as IDaFreeSlotViewModel,
  MrfiktivDAServiceEventDetailViewModelGen,
  MrfiktivDaControllerGetServiceEventParamsGen,
  MrfiktivDaControllerGetServiceEventsParamsGen,
  MrfiktivDaSetupViewModelGen,
  MrfiktivDaWorkshopServicesViewModelGen,
  MrfiktivDaWorkshopTasksViewModelGen,
  MrfiktivKsrContactInfoViewModelGen,
  MrfiktivKsrControllerGetCustomerContactInfoParamsGen,
  MrfiktivUnfallnaviExportReportDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import {
  MrfiktivDaEventTypesViewModelGen,
  MrfiktivDaResourcesViewModelGen
} from "./../../services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "export",
  store
})
export class ExportStore extends VuexModule {
  private _exportReportMetas: Map<string, IExportReportMeta[]> = new Map<string, IExportReportMeta[]>();

  private _exportReportMeta: IExportReportMeta[] = [];

  private _isExportReportMetasLoading = true;

  private _ksrDocuments: IKsrJobDetailViewmodel[] = [];

  private _isKsrSetup: boolean | undefined = undefined;

  private _isKsrSetupCheckLoaded = false;

  private _ksrSetupCheckAsync: Promise<void> | undefined = undefined;

  private _ksrDocument: IKsrJobDetailViewmodel | undefined = undefined;

  /**
   * The id of the partner for whom ksr/ da/ pdr was checked last
   */
  private _lastPartnerSetupChecked = "";

  get exportReportMetas(): Map<string, IExportReportMeta[]> {
    return this._exportReportMetas;
  }

  get exportReportMeta(): IExportReportMeta[] {
    return this._exportReportMeta;
  }

  get isExportReportMetasLoading(): boolean {
    return this._isExportReportMetasLoading;
  }

  get ksrDocuments(): IKsrJobDetailViewmodel[] {
    return this._ksrDocuments;
  }

  get isKsrSetup(): boolean | undefined {
    return this._isKsrSetup;
  }

  get isKsrSetupCheckLoaded(): boolean {
    return this._isKsrSetupCheckLoaded;
  }

  get ksrSetupCheckAsync(): Promise<void> | undefined {
    return this._ksrSetupCheckAsync;
  }

  get ksrDocument(): IKsrJobDetailViewmodel | undefined {
    return this._ksrDocument;
  }

  get lastPartnerSetupChecked() {
    return this._lastPartnerSetupChecked;
  }

  @Mutation
  mutateLastPartnerSetupChecked(lastPartnerSetupChecked: string) {
    this._lastPartnerSetupChecked = lastPartnerSetupChecked;
  }

  /**
   * adds meta data documents for a report to a mapping of meta data to report id
   * @param exportReportMeta
   */
  @Mutation
  setToExportReportMetas(exportReportMeta: IExportReportMeta[]) {
    const reportMeta = this._exportReportMetas.get(exportReportMeta[0].reportId) || [];

    for (const meta of exportReportMeta) {
      const index = reportMeta?.findIndex(x => x._id === meta._id);
      if (index !== undefined && index >= 0) {
        reportMeta[index] = meta;
      } else {
        reportMeta.push(meta);
      }
    }

    this._exportReportMetas.set(exportReportMeta[0].reportId, reportMeta);
  }

  @Mutation
  setExportReportMetas(exportReportMetas: Map<string, IExportReportMeta[]>) {
    this._exportReportMetas = exportReportMetas;
  }

  @Mutation
  setExportReportMeta(exportReportMeta: IExportReportMeta[]) {
    this._exportReportMeta = exportReportMeta;
  }

  @Mutation
  setKsrDocuments(ksrDocuments: IKsrJobDetailViewmodel[]) {
    this._ksrDocuments = ksrDocuments;
  }

  @Mutation
  setIsExportReportMetasLoading(isExportReportMetasLoading: boolean) {
    this._isExportReportMetasLoading = isExportReportMetasLoading;
  }

  @Mutation
  mutateIsKsrSetup(isKsrSetup?: boolean) {
    this._isKsrSetup = isKsrSetup;
  }

  @Mutation
  setIsKsrSetupCheckLoaded(isKsrSetupCheckLoaded: boolean) {
    this._isKsrSetupCheckLoaded = isKsrSetupCheckLoaded;
  }

  @Mutation
  setKsrSetupCheckAsync(ksrSetupCheckAsync: Promise<void> | undefined) {
    this._ksrSetupCheckAsync = ksrSetupCheckAsync;
  }

  @Mutation
  setKsrDocument(ksrDocument: IKsrJobDetailViewmodel) {
    this._ksrDocument = ksrDocument;
  }

  @Action
  async handleKsrSetupCheckAsync(ksrSetupCheckAsync: Promise<void> | undefined) {
    this.context.commit("setKsrSetupCheckAsync", ksrSetupCheckAsync);
  }

  @Action
  async getExportReportMetasByPartnerId(partnerId: string) {
    this.context.commit("setIsExportReportMetasLoading", true);
    const exportReportMetas = await MetaService.getAllExportMetaData(partnerId);
    exportReportMetas.forEach(exportReportMeta => {
      this.context.commit("setToExportReportMetas", [exportReportMeta]);
    });
    this.context.commit("setIsExportReportMetasLoading", false);
  }

  @Action
  async getExportReportMetaByPartnerIdAndReportId(partnerIdAndReportId: any) {
    const meta = await MetaService.getExportReportMetaDataByReportAndPartner(
      partnerIdAndReportId.reportId,
      partnerIdAndReportId.partnerId
    );

    this.context.commit("setExportReportMeta", meta);

    if (meta.length > 0) {
      this.context.commit("setToExportReportMetas", meta);
    }
    return meta;
  }

  @Action
  async handleDelete(partnerIdAndMetaId: any) {
    await MetaService.deleteMeta(partnerIdAndMetaId.metaId, partnerIdAndMetaId.partnerId);

    await this.getExportReportMetasByPartnerId(partnerIdAndMetaId.partnerId);
  }

  @Action
  async getKsrDocuments(partnerIdAndNumberPlateAndAmount: any) {
    const alreadyKnownKsrJobs: number[] = [];
    this.ksrDocuments.forEach(ksrJob => {
      alreadyKnownKsrJobs.push(Number(ksrJob.jobId));
    });

    const res = await KsrService.getJobDetailByNumberPlate(
      partnerIdAndNumberPlateAndAmount.partnerId,
      partnerIdAndNumberPlateAndAmount.numberPlate,
      Number(partnerIdAndNumberPlateAndAmount.amount),
      alreadyKnownKsrJobs
    );

    this.context.commit("setKsrDocuments", res);

    return res;
  }

  @Action
  async getJobDetailById(partnerIdAndJobId: { partnerId: string; jobId: string }): Promise<IKsrJobDetailViewmodel> {
    const ksrDocument = await KsrService.getJobDetailById(partnerIdAndJobId.partnerId, partnerIdAndJobId.jobId);

    this.context.commit("setKsrDocument", ksrDocument);
    return ksrDocument;
  }

  @Action
  async getCustomerContactInfo(
    data: MrfiktivKsrControllerGetCustomerContactInfoParamsGen
  ): Promise<MrfiktivKsrContactInfoViewModelGen[]> {
    const info = await KsrService.getCustomerContactInfo(data.partnerId, data.email, data.phoneNumber);

    return info;
  }

  @Action
  clearKsrDocument() {
    this.context.commit("setKsrDocument", undefined);
  }

  @Action
  clearKsrDocuments() {
    this.context.commit("setKsrDocuments", []);
  }

  @Action
  async ksrExportReport(ksrExportReportDto: IKsrExportReportDto): Promise<void> {
    await KsrService.ksrExportReport(PartnerModule.partner._id, PartnerModule.report._id, ksrExportReportDto);
  }

  @Action
  handleSetIsKsrSetupCheckLoaded(isKsrSetupCheckLoaded: boolean) {
    this.context.commit("setIsKsrSetupCheckLoaded", isKsrSetupCheckLoaded);
  }

  @Action
  setIsKsrSetup(isKsrSetup: boolean) {
    this.context.commit("mutateIsKsrSetup", isKsrSetup);
  }

  @Action
  async checkIsKsrSetup(data: { partnerId: string; isForceRefresh?: boolean }) {
    if (
      this.lastPartnerSetupChecked !== data.partnerId ||
      typeof this.isKsrSetup === "undefined" ||
      data.isForceRefresh
    ) {
      this.handleSetIsKsrSetupCheckLoaded(false);
      try {
        const isSetup = (await KsrService.checkIsKsrSetup(data.partnerId)).isSetup;
        this.context.commit("mutateLastPartnerSetupChecked", data.partnerId);
        this.context.commit("mutateIsKsrSetup", isSetup);
      } catch (e) {
        this.context.commit("mutateIsKsrSetup", false);
        throw e;
      } finally {
        this.handleSetIsKsrSetupCheckLoaded(true);
      }
    }
  }

  private _daSetup: MrfiktivDaSetupViewModelGen | undefined = undefined;

  private _isDaSetup: boolean | undefined = undefined;

  private _isDaSetupCheckLoaded = false;

  private _daSetupCheckAsync: Promise<void> | undefined = undefined;

  private _daFreeSlot: IDaFreeSlotViewModel | undefined = undefined;

  private _daResources: MrfiktivDaResourcesViewModelGen | undefined = undefined;

  private _daEventTypes: MrfiktivDaEventTypesViewModelGen | undefined = undefined;

  private _daServiceEvents: MrfiktivDAServiceEventDetailViewModelGen[] = [];

  private _daWorkshopServices: MrfiktivDaWorkshopServicesViewModelGen | undefined = undefined;

  private _daWorkshopTasks: MrfiktivDaWorkshopTasksViewModelGen | undefined = undefined;

  private _daMetaData: IDaMetaData = {
    serviceEventId: "",
    workshopTaskId: ""
  };

  get isDaSetup(): boolean | undefined {
    return this._isDaSetup;
  }

  get daSetup(): MrfiktivDaSetupViewModelGen | undefined {
    return this._daSetup;
  }

  get isDaSetupCheckLoaded(): boolean {
    return this._isDaSetupCheckLoaded;
  }

  get daSetupCheckAsync(): Promise<void> | undefined {
    return this._daSetupCheckAsync;
  }

  get daFreeSlot(): IDaFreeSlotViewModel | undefined {
    return this._daFreeSlot;
  }

  get daResources(): MrfiktivDaResourcesViewModelGen | undefined {
    return this._daResources;
  }

  get daEventTypes(): MrfiktivDaEventTypesViewModelGen | undefined {
    return this._daEventTypes;
  }

  get daServiceEvents(): MrfiktivDAServiceEventDetailViewModelGen[] {
    return this._daServiceEvents;
  }

  get daWorkshopServices(): MrfiktivDaWorkshopServicesViewModelGen | undefined {
    return this._daWorkshopServices;
  }

  get daWorkshopTasks(): MrfiktivDaWorkshopTasksViewModelGen | undefined {
    return this._daWorkshopTasks;
  }

  get daMetaData(): IDaMetaData {
    return this._daMetaData;
  }

  @Mutation
  mutateDaSetup(daSetup: MrfiktivDaSetupViewModelGen) {
    this._daSetup = daSetup;
  }

  @Mutation
  mutateIsDaSetup(isDaSetup?: boolean) {
    this._isDaSetup = isDaSetup;
  }

  @Mutation
  mutateIsDaSetupCheckLoaded(isDaSetupCheckLoaded: boolean) {
    this._isDaSetupCheckLoaded = isDaSetupCheckLoaded;
  }

  @Mutation
  mutateDaSetupCheckAsync(daSetupCheckAsync: Promise<void> | undefined) {
    this._daSetupCheckAsync = daSetupCheckAsync;
  }

  @Mutation
  mutateDaFreeSlot(daFreeSlot: IDaFreeSlotViewModel) {
    this._daFreeSlot = daFreeSlot;
  }

  @Mutation
  mutateDaResources(daResources: MrfiktivDaResourcesViewModelGen) {
    this._daResources = daResources;
  }

  @Mutation
  mutateDaEventTypes(daEventTypes: MrfiktivDaEventTypesViewModelGen) {
    this._daEventTypes = daEventTypes;
  }

  @Mutation
  mutateDaServiceEvents(daServiceEvents: MrfiktivDAServiceEventDetailViewModelGen[]) {
    this._daServiceEvents = daServiceEvents;
  }

  @Mutation
  mutateDaWorkshopServices(daWorkshopServices: MrfiktivDaWorkshopServicesViewModelGen) {
    this._daWorkshopServices = daWorkshopServices;
  }

  @Mutation
  mutateDaWorkshopTasks(daWorkshopTasks: MrfiktivDaWorkshopTasksViewModelGen) {
    this._daWorkshopTasks = daWorkshopTasks;
  }

  @Mutation
  mutateDaMetaData(daMetaData: IDaMetaData) {
    if (daMetaData.numberPlate) {
      this._daMetaData.numberPlate = daMetaData.numberPlate;
    }

    if (daMetaData.serviceEventId) {
      this._daMetaData.serviceEventId = daMetaData.serviceEventId;
    }

    if (daMetaData.workshopTaskId) {
      this._daMetaData.workshopTaskId = daMetaData.workshopTaskId;
    }
  }

  @Action
  handleDaSetupCheckAsync(daSetupCheckAsync: Promise<void> | undefined) {
    this.context.commit("mutateDaSetupCheckAsync", daSetupCheckAsync);
  }

  @Action
  setIsDaSetup(isDaSetup: boolean) {
    this.context.commit("mutateIsDaSetup", isDaSetup);
  }

  @Action
  async checkIsDaSetup(data: { partnerId: string; isForceRefresh?: boolean }) {
    if (
      this.lastPartnerSetupChecked !== data.partnerId ||
      typeof this.isDaSetup === "undefined" ||
      data.isForceRefresh
    ) {
      try {
        this.context.commit("mutateIsDaSetupCheckLoaded", false);
        const daSetup = await DaService.getDaConfiguration(data.partnerId);
        const isSetup = daSetup.isSetup;
        this.context.commit("mutateLastPartnerSetupChecked", data.partnerId);
        this.context.commit("mutateDaSetup", daSetup);
        this.context.commit("mutateIsDaSetup", isSetup);
      } catch (e) {
        this.context.commit("mutateIsDaSetup", false);
        throw e;
      } finally {
        this.context.commit("mutateIsDaSetupCheckLoaded", true);
      }
    }
  }

  @Action
  async getDaFreeSlot(getFreeSlotDto: IGetFreeSlotDto) {
    const freeSlots = await DaService.getFreeSlot(getFreeSlotDto);

    this.context.commit("mutateDaFreeSlot", freeSlots);
  }

  @Action
  clearDaFreeSlot() {
    this.context.commit("mutateDaFreeSlot", undefined);
  }

  @Action
  clearDaServiceEvents() {
    this.context.commit("mutateDaServiceEvents", []);
  }

  @Action
  async getDaResources(partnerId: string) {
    const resources = await DaService.getResources(partnerId);

    this.context.commit("mutateDaResources", resources);
  }

  @Action
  async getDaEventTypes(partnerId: string) {
    const eventTypes = await DaService.getEventTypes(partnerId);

    this.context.commit("mutateDaEventTypes", eventTypes);
  }

  @Action
  async getDaServiceEvents(query: MrfiktivDaControllerGetServiceEventsParamsGen) {
    const serviceEvents = await DaService.getServiceEvents(query);

    this.context.commit("mutateDaServiceEvents", serviceEvents);
  }

  @Action
  async getDaServiceEvent(query: MrfiktivDaControllerGetServiceEventParamsGen) {
    const serviceEvent = await DaService.getServiceEvent(query);

    this.context.commit("mutateDaServiceEvents", [serviceEvent]);
  }

  @Action
  async getDaWorkshopServices(partnerId: string) {
    const workshopServices = await DaService.getWorkshopServices(partnerId);

    this.context.commit("mutateDaWorkshopServices", workshopServices);
  }

  @Action
  async getDaWorkshopTasks(partnerId: string) {
    const workshopTasks = await DaService.getWorkshopTasks(partnerId);

    this.context.commit("mutateDaWorkshopTasks", workshopTasks);
  }

  @Action
  async exportReportToDa(envelope: IDaExportReportDtoEnvelope) {
    return await DaService.exportReportToDa(envelope.partnerId, envelope.reportId, envelope.daExportReportDto);
  }

  @Action
  setDaMetaData(data: IDaMetaData) {
    this.context.commit("mutateDaMetaData", data);
  }

  @Action
  clearDaExportMeta() {
    this.context.commit("mutateDaMetaData", {
      serviceEventId: "",
      workshopTaskId: ""
    } as IDaMetaData);
  }

  private _isPdrSetup: boolean | undefined = undefined;

  private _isPdrSetupCheckLoaded = false;

  private _pdrSetupCheckAsync: Promise<void> | undefined = undefined;

  get isPdrSetup(): boolean | undefined {
    return this._isPdrSetup;
  }

  get isPdrSetupCheckLoaded(): boolean {
    return this._isPdrSetupCheckLoaded;
  }

  get pdrSetupCheckAsync(): Promise<void> | undefined {
    return this._pdrSetupCheckAsync;
  }

  @Mutation
  mutateIsPdrSetup(isPdrSetup?: boolean) {
    this._isPdrSetup = isPdrSetup;
  }

  @Mutation
  mutateIsPdrSetupCheckLoaded(isPdrSetupCheckLoaded: boolean) {
    this._isPdrSetupCheckLoaded = isPdrSetupCheckLoaded;
  }

  @Mutation
  mutatePdrSetupCheckAsync(pdrSetupCheckAsync: Promise<void> | undefined) {
    this._pdrSetupCheckAsync = pdrSetupCheckAsync;
  }

  @Action
  handlePdrSetupCheckAsync(pdrSetupCheckAsync: Promise<void> | undefined) {
    this.context.commit("mutatePdrSetupCheckAsync", pdrSetupCheckAsync);
  }

  @Action
  setIsPdrSetup(isPdrSetup: boolean) {
    this.context.commit("mutateIsPdrSetup", isPdrSetup);
  }

  @Action
  async checkIsPdrSetup(data: { partnerId: string; isForceRefresh?: boolean }) {
    if (
      this.lastPartnerSetupChecked !== data.partnerId ||
      typeof this.isPdrSetup === "undefined" ||
      data.isForceRefresh
    ) {
      this.context.commit("mutateIsPdrSetupCheckLoaded", false);
      try {
        const pdrSetup = await pdrService.getPdrConfiguration(data.partnerId);
        const isSetup = pdrSetup.isSetup;
        this.context.commit("mutateLastPartnerSetupChecked", data.partnerId);
        this.context.commit("mutateIsPdrSetup", isSetup);
      } catch (e) {
        this.context.commit("mutateIsPdrSetup", false);

        throw e;
      } finally {
        this.context.commit("mutateIsPdrSetupCheckLoaded", true);
      }
    }
  }

  @Action
  async checkSetups() {
    const partnerId = PartnerModule.partner._id;
    const da = this.checkIsDaSetup({ partnerId: partnerId, isForceRefresh: true });
    const ksr = this.checkIsKsrSetup({ partnerId: partnerId, isForceRefresh: true });
    const pdr = this.checkIsPdrSetup({ partnerId: partnerId, isForceRefresh: true });
    await Promise.all([da, ksr, pdr]);
  }

  @Action
  async exportReportToPdr(exportData: { partnerId: string; reportId: string }) {
    await pdrService.exportReportToPdr(exportData.partnerId, exportData.reportId);
  }

  private _unfallnaviMetaData: MrfiktivUnfallnaviExportReportDtoGen = {
    isDataProcessingAgreement: false
  };
  get unfallnaviMetaData(): MrfiktivUnfallnaviExportReportDtoGen {
    return this._unfallnaviMetaData;
  }
  @Mutation
  mutateUnfallnaviMetaData(unfallnaviMetaData: MrfiktivUnfallnaviExportReportDtoGen) {
    if (unfallnaviMetaData.isDataProcessingAgreement !== undefined) {
      this._unfallnaviMetaData.isDataProcessingAgreement = unfallnaviMetaData.isDataProcessingAgreement;
    }
  }

  @Action
  setUnfallnaviMetaData(unfallnaviMetaData: MrfiktivUnfallnaviExportReportDtoGen) {
    this.context.commit("mutateUnfallnaviMetaData", unfallnaviMetaData);
  }

  @Action
  clearUnfallnaviMetaData() {
    const mrfiktivUnfallnaviExportReportDtoGen: MrfiktivUnfallnaviExportReportDtoGen = {
      isDataProcessingAgreement: false
    };
    this.context.commit("mutateUnfallnaviMetaData", mrfiktivUnfallnaviExportReportDtoGen);
  }
}

export const ExportModule = getModule(ExportStore);
