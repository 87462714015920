var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.LanguageDialog.title'),
      "subtitle": _vm.$t('components.LanguageDialog.subtitle'),
      "hide-close": true,
      "hide-left": true,
      "isDialogActive": _vm.value,
      "persistent": ""
    },
    on: {
      "rightClick": _vm.emitNext,
      "close": _vm.emitNext
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.browserLanguage
    }
  }), _vm.value ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.items,
      "item-text": "text",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("getFlagEmojiByLanguage")(data.item.value)))]), _c('span', {
          staticClass: "pl-4"
        }, [_vm._v(_vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(data.item.value))))])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-avatar', [_vm._v(" " + _vm._s(_vm._f("getFlagEmojiByLanguage")(data.item.value)) + " ")]), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(data.item.value))))])], 1)];
      }
    }], null, false, 1021826145),
    model: {
      value: _vm.local,
      callback: function callback($$v) {
        _vm.local = $$v;
      },
      expression: "local"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }